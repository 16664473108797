<template>
  <footer v-if="!!headerFooter.footer" :class="{ safari: isSafari }">
    <v-divider class="mb-1 footer-divider" />

    <FooterFirstRow :footer="headerFooter?.footer" />

    <v-divider class="mt-3 mb-3 footer-divider" />

    <FooterSecondRow :footer="headerFooter?.footer" />

    <v-divider class="mt-3 mb-3 footer-divider" />

    <FooterThirdRow />
  </footer>
</template>

<script setup lang="ts">
import { useHeaderFooterStore } from '@/stores/headerFooter'

const { isSafari } = useDevice()
const headerFooterStore = useHeaderFooterStore()
const { headerFooter } = storeToRefs(headerFooterStore)
if (headerFooter.value.footer === null) await headerFooterStore.getHeaderFooterData()

const { $clickskeksConsent } = useNuxtApp()
const { gtm } = useGTagManager()
const consentObject = computed(() => $clickskeksConsent.getObject())
const runtimeConfig = useRuntimeConfig()
const handleGtm = () => {
  gtm?.enable(consentObject.value['/'].embeddings.includes(runtimeConfig.public.gtmCookieEmbedCode))
}

onMounted(() => {
  window.addEventListener('ccm19WidgetClosed', handleGtm)
})
onUnmounted(() => {
  window.removeEventListener('ccm19WidgetClosed', handleGtm)
})
</script>
<style lang="scss" scoped>
.footer-divider {
  border: 1px solid rgb(var(--v-theme-border));
}

.safari {
  :deep(.v-container) {
    max-width: 90% !important;
    @media (min-width: 1800px) {
      max-width: 1600px !important;
    }
  }
}
</style>
